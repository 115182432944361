import { useEffect, useState } from "react";
import { useSocket } from '../../contexts/SocketContext';
import toast from 'react-hot-toast';
import { Select, SelectItem } from "@nextui-org/react";
import { Card, CardHeader, CardBody } from "@nextui-org/react";
import { Button } from "@nextui-org/react";



const FormularioCargar = () => {
    const socket = useSocket();
    const [empresa, setEmpresa] = useState(null);
    useEffect(() => {
        socket.off('cargarContactosPanel');


        socket.on('contactsSendend', (response) => {
            console.log('Respuesta recibida:', response);
        
            if (typeof response === 'object') {
                console.log('Contactos eliminados:', response.contactos_eliminados);
                toast.success('Contactos eliminados exitosamente.');
            } else if (typeof response === 'string') {
                try {
                    const jsonResponse = JSON.parse(response);
                    console.log('Respuesta parseada:', jsonResponse);
                    toast.success('Contactos eliminados exitosamente.');
                } catch (e) {
                    console.error('Error al parsear JSON:', e);
                    toast.error('No se pudo procesar la respuesta del servidor.');
                }
            } else {
                console.warn('Tipo de respuesta inesperado:', typeof response);
                toast.error('Se recibió una respuesta inesperada del servidor.');
            }
        });

        return () => {
            socket.off('cargarContactosPanel');
        }

        // eslint-disable-next-line
    }, []);


    const handleCargar = () => {

        if (empresa === null) {
            toast.error('Debes seleccionar la empresa a la que cargar los contactos');
            return;
        }
        socket.emit('cargarContactosPanel', empresa, localStorage.getItem('token-autocall-ig'));
    }



    return (


        <Card className="p-6 flex flex-col justify-between w-fit min-w-96" >
            <CardHeader className="flex items-center justify-between">
                <h2 className="text-xl">Cargar Contactos</h2>
            </CardHeader>
            <CardBody>
                <p className="text-gray-400 text-[13px]">Selecciona la empresa a la que cargar los contactos</p>
                <Select placeholder="Seleccionar empresa" className="w-full mb-4" onChange={(e) => setEmpresa(e.target.value)}>
                    <SelectItem value="Integram" key="1" >Integram</SelectItem>
                    <SelectItem value="JQS" key="2">JQS</SelectItem>
                    <SelectItem value="Kram" key="3">Kram</SelectItem>
                </Select>




                <Button color="primary" className="mt-4" onClick={handleCargar}>
                    Cargar</Button>
            </CardBody>

        </Card >
    );
}
export default FormularioCargar;