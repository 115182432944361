import { useEffect, useState } from "react";
import { useSocket } from '../../contexts/SocketContext';
import toast from 'react-hot-toast';
import { Select, SelectItem } from "@nextui-org/react";
import { Card, CardHeader, CardBody } from "@nextui-org/react";
import { Input } from "@nextui-org/react";
import { Spinner } from "@nextui-org/react";
import { Button } from "@nextui-org/react";



const FormularioEnviar = () => {
    const socket = useSocket();
    const [contactosListosParaEnviar, setContactosListosParaEnviar] = useState(0);
    const [loading, setLoading] = useState(true);
    const [inicio, setInicio] = useState(null);
    const [final, setFinal] = useState(null);
    const [empresa, setEmpresa] = useState(null);

    useEffect(() => {
        socket.off('valoresContactosResponse');
        socket.off('contactsSendend');

        socket.emit('getValoresContactos', localStorage.getItem('token-autocall-ig'));

        socket.on('contactsSendend', (response) => {
            toast.success('Resultados del envio de contactos añadidos correctamente: ' + response.contactos_anadidos + ' contactos repetidos o erroneos: ' + response.contactos_existentes);
        }
        );

        socket.on('valoresContactosResponse', (response) => {

            setContactosListosParaEnviar(response.contactosListosParaEnviar);
            setLoading(false);
        }
        );


        return () => {
            socket.off('valoresContactosResponse');
            socket.off('contactsSendend');
        }

        // eslint-disable-next-line
    }, []);

    const handleEnviar = () => {

        if (empresa === null || inicio === null || final === null) {
            toast.error('Debes seleccionar la empresa y los contactos a enviar');
            return;
        }

        if (parseInt(inicio) > parseInt(final)) {
            toast.error('El contacto de inicio no puede ser mayor al contacto final');
            return;
        }

        //Aqui añadimos un alert para confirmar el envio de los contactos
        if (!window.confirm('¿Estas seguro de enviar los contactos a la empresa ' + empresa + ' desde el contacto ' + inicio + ' hasta el contacto ' + final + '?')) {
            return;
        }



        socket.emit('enviarContactosTotal', { empresa, inicio, final, token: localStorage.getItem('token-autocall-ig') });

    }



    if (loading) {
        return <div className="loader"><Spinner size="large" /></div>
    }

    return (


        <Card className="p-6 flex flex-col justify-between w-fit min-w-96" >
            <CardHeader className="flex items-center justify-between">
                <div className="flex items-start flex-col">
                    <h1 className="text-4xl">{contactosListosParaEnviar}
                    </h1>
                    <span className="text-gray-400 text-[13px]">Contactos listos para enviar</span>
                </div>

            </CardHeader>
            <CardBody>
                <p className="text-gray-400 text-[13px]">Selecciona la empresa a la que enviar los contactos</p>
                <Select placeholder="Seleccionar empresa" className="w-full mb-4" onChange={(e) => setEmpresa(e.target.value)}>
                    <SelectItem value="Integram" key="1" >Integram</SelectItem>
                    <SelectItem value="JQS" key="2">JQS</SelectItem>
                    <SelectItem value="Kram" key="3">Kram</SelectItem>
                </Select>

                <p className="text-gray-400 text-[13px]">Selecciona de que contacto a que contacto deseas enviar</p>
                <div className="flex gap-4 items-center">
                    <Input placeholder="Inicio" type="number" onChange={(e) => setInicio(e.target.value)} value={inicio} />
                    <span className="text-gray-400 text-sm">al</span>
                    <Input placeholder="Final" type="number" onChange={(e) => setFinal(e.target.value)} value={final} />
                </div>


                <Button color="primary" className="mt-4" onClick={handleEnviar}>
                    Enviar</Button>
            </CardBody>

        </Card >
    );
}
export default FormularioEnviar;