import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import EditotHtml from "../componentes/configuracionemails/EditorHtml";

const ConfiguracionEmails = () => {

    return (
        <section>
            <Breadcrumbs isDisabled className="mb-6">
                <BreadcrumbItem>Principal</BreadcrumbItem>
                <BreadcrumbItem active>Configuración emails</BreadcrumbItem>
            </Breadcrumbs>
            <h2 className="text-xl">
                Configuración Emails
            </h2>
            <p className="text-gray-400 text-[13px]">Revisa las estadísticas de uso del servicio</p>
            <div className="py-3">
                <EditotHtml />
            </div>

        </section>
    );
}
export default ConfiguracionEmails;