import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from 'react';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useSocket } from '../../contexts/SocketContext';
import toast from 'react-hot-toast';

const ModalTemp = ({ json }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const socket = useSocket();
  const [toastId, setToastId] = useState(null);



  useEffect(() => {
    if (json) {
      onOpen();
    }

    // eslint-disable-next-line
  }, [json]);

  //contactsUploaded





  const handleConfirm = () => {



    socket.emit('uploadContactsConfirmar' );

    const id = toast.loading('Subiendo contactos, por favor espere...');
    setToastId(id);
    onClose();
  };

  useEffect(() => {
    socket.off('contactsUploaded');


    const handleContactsUploaded = (message) => {
      console.log(message);
      toast((t) => (
        <div>
          <p className="font-medium ">Resultados de la inserción de contactos</p>
          <p className="text-sm text-gray-600">
            Contactos subidos correctamente: {message?.result?.ContactosSubidosCorrectamente ?? 0}
          </p>
          <p className="text-sm text-gray-600">
            Contactos repetidos o erroneos: {message?.result?.ContactosRepetidos ?? 0}
          </p>
        </div>

      ), {
        duration: 4000,
        style: {
          width: 'auto',
          color: '#000',
        }
      });

      if (toastId) {
        toast.dismiss(toastId);
        setToastId(null);
      }

    // Descargar el archivo Excel si existe
  if (message.file) {
    const link = document.createElement('a');
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${message.file}`;
    link.download = 'contactos_repetidos.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
    };


    socket.on('contactsUploaded', handleContactsUploaded);

    return () => {
      socket.off('contactsUploaded', handleContactsUploaded);
    };

    // eslint-disable-next-line
  }, [toastId]);



  return (
    <>
      <Modal backdrop={"opaque"} isOpen={isOpen} onClose={onClose} className="w-full max-w-2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-0">
                Fragmento de los contactos
                <p className="text-gray-400 text-[13px] font-light">Revisa que las columnas sean correctas</p>
              </ModalHeader>
              <ModalBody>
                <Table style={{ width: '100%' }}>
                  <TableHeader>
                    <TableColumn>ID</TableColumn>
                    <TableColumn>Empresa</TableColumn>
                    <TableColumn>Teléfono</TableColumn>
                    <TableColumn>Provincia</TableColumn>
                    <TableColumn>Persona de contacto</TableColumn>
                  </TableHeader>
                  <TableBody>
                    {json.map((contacto, index) => (
                      <TableRow key={index}>
                        <TableCell>{contacto.__EMPTY}</TableCell>
                        <TableCell>{contacto.Nombre}</TableCell>
                        <TableCell>{contacto.Teléfono}</TableCell>
                        <TableCell>{contacto.Provincia}</TableCell>
                        <TableCell>{contacto.PC}</TableCell>
                      </TableRow>
                    ))}
              
                  </TableBody>
                </Table>
               
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={handleConfirm}>
                  Enviar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
export default ModalTemp;