import { Button } from "@nextui-org/react";
import { useSocket } from '../../contexts/SocketContext';
import { useEffect, useState } from 'react';
const CardDescargaNifs = () => {
    const socket = useSocket();

    useEffect(() => {
        socket.off('getNifs');

        socket.on('getNifs', (response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'nifs.xlsx');
            document.body.appendChild(link);
            link.click();
        }
        );


        return () => {
            socket.off('getNifs');
        }

    }, [socket]);

    const handleGetNifs = () => {
        socket.emit('getNifs', localStorage.getItem('token-autocall-ig'));
    }


    return (
        <div className="h-full mt-5">
            <p>Descarga todos los NIFs que se encuentran en la base de datos en formato Excel</p>
            <Button className="mt-3" auto color="primary" onClick={handleGetNifs}>
                Descargar NIFs
            </Button>
        </div>
    )
}
export default CardDescargaNifs;