import { Chip,Button } from "@nextui-org/react";
import { useState } from "react";
import Editor from 'react-simple-wysiwyg';

const EditotHtml = () => {
    const variables = [
        "%%EMPRESA_ORIGEN_DEL_CONTACTO%%",
        "%%NOMBRE_EMPRESA%%",
        "%%TELEFONO_EMPRESA%%",
        "%%EMAIL_RECEPCION%%",
    ]
    const [html, setHtml] = useState('');

    function onChange(e) {
      setHtml(e.target.value);
    }

    function openInNewTab() {
        const newWindow = window.open('', '_blank');
        newWindow.document.write(html);
    }

    return (
        <>
        <p className="text-sm mt-4 text-gray-400">Variables</p>
        <div className="flex flex-row gap-3">
            {variables.map((variable, index) => (
                <Chip key={index} className="mt-2 mb-3 pointer"
                    color="primary"
                    size="small"
                    onClick={() => setHtml(html + " " +variable + " ")}
                >{variable}</Chip>
            ))}
        </div>
        <Editor value={html} onChange={onChange} />
        <Button className="mt-4" color="primary" onClick={openInNewTab}>Abrir en nueva pestaña</Button>
        </>
    );
}
export default EditotHtml;