import { Card, CardBody, CardHeader } from '@nextui-org/react';
import BloqueoEmpresaLlamdas from '../configuracionllamadas/BloqueoEmpresaLlamdas';

const AccionesRapidas = () => {
    return (
        <>
            <Card className="p-3 h-full">
            <CardHeader className="flex flex-col items-start">
                <h2 className="text-xl">
                    Acciones rápidas
                </h2>
                <p className="text-gray-400 text-[13px]">Acceso rapido a funcionalidades</p>
            </CardHeader>
                <CardBody>
                   
                    <div className="mt-8 flex flex-col gap-4">
                        
                        <BloqueoEmpresaLlamdas />
                    </div>
                </CardBody>
            </Card>

        </>
    )
}
export default AccionesRapidas;