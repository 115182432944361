import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import FormularioSubirClientes from "../componentes/cargarclientes/FormularioSubirClientes";

const AnadirCliente = () => {
    return (
        <section>
            <Breadcrumbs isDisabled className="mb-6">
                <BreadcrumbItem>Principal</BreadcrumbItem>
                <BreadcrumbItem active>Subir Clientes</BreadcrumbItem>
            </Breadcrumbs>
            <h2 className="text-xl">
                Añadir clientes
            </h2>
            <p className="text-gray-400 text-[13px]">Sube un excel con los clientes que deseas añadir "nombre, nif, empresa" </p>
            <div className="py-3">
            <FormularioSubirClientes />
            </div>
        
        </section>
    );
}
export default AnadirCliente;