import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import EstadisticasTable from "../componentes/estadisticas/EstadisticasTable";

const Estadisticas = () => {
    return (
        <section>
        <Breadcrumbs isDisabled className="mb-6">
            <BreadcrumbItem>Administración</BreadcrumbItem>
            <BreadcrumbItem active>Estadísticas</BreadcrumbItem>
        </Breadcrumbs>
        <h2 className="text-xl">
            Estadísiticas
        </h2>
        <p className="text-gray-400 text-[13px]">Revisa las estadísticas de uso del servicio</p>
        <div className="py-3">
        <EstadisticasTable />
        </div>
    
    </section>
    );
    }
export default Estadisticas;