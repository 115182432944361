
import { useSocket } from '../../contexts/SocketContext';
import { useState, useEffect } from 'react';

const FormularioSubirClientes = () => {

    const socket = useSocket();



    useEffect(() => {
        if (socket) {
            socket.on('subirClientesResponse', (data) => {
             console.log(data);
            });
        }
        return () => {
            if (socket) {
                socket.off('subirClientesResponse');
            }
        };
    }
        , [socket]);



        let chunkIndex = 0;

        const handleUpload = (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            const CHUNK_SIZE = 240 * 1024; // 64 KB por fragmento
        
            reader.onload = function(event) {
                const base64Chunk = event.target.result.split(',')[1]; // Extraer Base64 sin prefijo
             
        
                socket.emit('uploadChunk', {
                    chunk: base64Chunk,
                    index: chunkIndex,
                    fileName: file.name,
                });
        
                chunkIndex += 1;
        
                if (offset < file.size) {
                    readNextChunk();
                } else {
                    console.log("Archivo enviado completamente");
                    socket.emit('uploadComplete', { fileName: file.name, token: localStorage.getItem('token-autocall-ig') });
                }
            };
        
            const readNextChunk = () => {
                const slice = file.slice(offset, offset + CHUNK_SIZE);
                offset += CHUNK_SIZE;
                reader.readAsDataURL(slice);
            };
        
            let offset = 0;
            readNextChunk();
        };
        
        



    return (

        <div className="flex items-center justify-center w-full">

        <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Haz clic aquí</span> o arrastra y suelta un archivo
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">Excel o CSV</p>
            </div>
            <input id="dropzone-file" type="file" className="hidden" onChange={handleUpload} accept=".xlsx, .xls" />
        </label>

    </div>

    );
}
export default FormularioSubirClientes;