import { Button, Card, CardBody, CardHeader, Skeleton } from "@nextui-org/react";
import { useSocket } from '../../contexts/SocketContext';
import { useEffect, useState } from 'react';
import { Progress } from "@nextui-org/react";
import { FiRefreshCcw } from "react-icons/fi";


const InformacionContactos = () => {
    const socket = useSocket();
    const [totalContactos, setTotalContactos] = useState(0);
    /*
       totalContcatos: totalContcatos,
        contactosListosParaEnviar: contactosListosParaEnviar,
        totalLlamadas: totalLlamadas,
        totalLlamadasNoContestadas: totalLlamadasNoContestadas,
        totalLlamadasContestadas: totalLlamadasContestadas
        */

    const [contactosListosParaEnviar, setContactosListosParaEnviar] = useState(0);
    const [totalLlamadas, setTotalLlamadas] = useState(0);
    const [totalLlamadasNoContestadas, setTotalLlamadasNoContestadas] = useState(0);
    const [totalLlamadasContestadas, setTotalLlamadasContestadas] = useState(0);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        socket.off('getValoresContactos');
        socket.off('valoresContactosResponse');
        socket.off('refreshValoresContactos');  

        socket.emit('getValoresContactos', localStorage.getItem('token-autocall-ig'));

        socket.on('valoresContactosResponse', (response) => {
            setTotalContactos(response.totalContcatos);
            setContactosListosParaEnviar(response.contactosListosParaEnviar);
            setTotalLlamadas(response.totalLlamadas);
            setTotalLlamadasNoContestadas(response.totalLlamadasNoContestadas);
            setTotalLlamadasContestadas(response.totalLlamadasContestadas);
            setLoading(false);
        }
        );

        return () => {
            socket.off('getValoresContactos');
            socket.off('valoresContactosResponse');
            socket.off('refreshValoresContactos');
        }
    }, [socket]);


    const handleRefresh = () => {
        socket.emit('refreshValoresContactos', localStorage.getItem('token-autocall-ig'));
    }



    return (
        <>
            {!loading &&
                (
                    <Card className="p-3 flex flex-col justify-between min-h-full">
                        <CardHeader className="flex items-center justify-between">
                            <div className="flex flex-col">
                                <h2 className="text-xl">
                                    Listado de contactos
                                </h2>
                                <p className="text-gray-400 text-[13px]">Total de contactos en la base de datos</p>
                            </div>
                            <Button className="absolute right-3" auto isIconOnly color="primary" onClick={handleRefresh}>
                                <FiRefreshCcw />
                            </Button>


                        </CardHeader>
                        <CardBody className=" flex flex-col justify-center ">
                            <h2 className="text-4xl flex  gap-1">
                                {contactosListosParaEnviar} <span className="text-gray-400 text-[13px]">/ {totalContactos}</span>
                            </h2>


                            <div className="pt-4 pb-4">

                                <Progress
                                    className='mt-2'
                                    size="sm"
                                    radius="sm"
                                    label={"Total llamadas hoy: " + totalLlamadas}
                                    value={totalLlamadas}
                                />

                                <Progress
                                    className='mt-2'
                                    size="sm"
                                    radius="sm"
                                    label={"No contestan: " + totalLlamadasNoContestadas}
                                    value={isNaN(totalLlamadasNoContestadas / totalLlamadas * 100) ? 0 : totalLlamadasNoContestadas / totalLlamadas * 100}
                                    showValueLabel={true}
                                />

                                <Progress
                                    className='mt-2'
                                    size="sm"
                                    radius="sm"
                                    label={"Contestan: " + totalLlamadasContestadas}
                                    value={isNaN(totalLlamadasContestadas / totalLlamadas * 100) ? 0 : totalLlamadasContestadas / totalLlamadas * 100}
                                    showValueLabel={true}
                                />


                            </div>

                        </CardBody>

                    </Card>
                )
            }

            {
                loading && (
                    <Card className="p-3 flex flex-col min-h-full">
                       
                        <CardBody className=" flex flex-col justify-center gap-3">
                        <div className="flex flex-col">
                                <Skeleton className="w-30 h-10" />
                                <h2 className="text-xl">
                                    
                                </h2>
                                <Skeleton className="w-25 h-5" />
                            </div>
                            <div className="flex items-center gap-1">
                                <Skeleton className="w-10 h-10" /> 
                                <Skeleton className="w-5 h-5" />
                            </div>
                            <Skeleton className="w-full h-5" />
                            <Skeleton className="w-full h-5" />
                            <Skeleton className="w-full h-5" />
                        </CardBody>


                    </Card>
                )

            }
        </>

    );
}
export default InformacionContactos;