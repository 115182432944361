const sqlDateToJsDate = (sqlDate) => {
    const sqlDateArr = sqlDate.split("T");
    const ymd = sqlDateArr[0].split("-");
    const hms = sqlDateArr[1].split(":");
    const jsDate = new Date(ymd[0], ymd[1] - 1, ymd[2], hms[0], hms[1], hms[2].split(".")[0]);
    return jsDate;
}
const numberToEmpresa = (number) => {
    if(number == 1){
        return "Integram";
    }else if(number == 2){
        return "JQS";
    }else if(number == 3){
        return "KRAM";
    }
}


const frequenciaToText = (frequencia) => {

    //spliteamos si tiene una coma
    frequencia = frequencia.split(",");
    //por cada elemento del array lo pasamos a texto

    let frecuenciaTexto = "";

    frequencia.forEach((element, index) => {

        switch (element) {
            case "1":
                element = "Lunes";
                break;
            case "2":
                element = "Martes";
                break;
            case "3":
                element = "Miercoles";
                break;
            case "4":
                element = "Jueves";
                break;
            case "5":
                element = "Viernes";
                break;
            case "6":
                element = "Sabado";
                break;
            case "7":
                element = "Domingo";
                break;
            default:
                break;
        }
        if (index > 0) {
            frecuenciaTexto += ", ";
        }
        frecuenciaTexto += element;
    });
    return frecuenciaTexto;
}

const horarioToText = (horario) => {

    //spliteamos si tiene una coma
    horario = horario.split(",");
    //por cada elemento del array lo pasamos a texto

    let horarioTexto = "";

    horario.forEach((element, index) => {

        switch (element) {
            case "1":
                element = "Horario de oficina mañana";
                break;
            case "2":
                element = "Horario de oficina tarde";
                break;
            default:
                break;
        }
        if (index > 0) {
            horarioTexto += ", ";
        }
        horarioTexto += element;
    }
    );
    return horarioTexto;
}

export { sqlDateToJsDate, frequenciaToText,horarioToText,numberToEmpresa };