
import User from "../componentes/dashboard/User";
import Estadísticas from "../componentes/dashboard/EstadisticasRapidas";
import Estado from "../componentes/dashboard/Estado";
import GraficoHoras from "../componentes/dashboard/GraficoHoras";
import AccionesRapidas from "../componentes/dashboard/AccionesRapidas";
import Asesor from "../componentes/dashboard/Asesor";
import InformacionContactos from "../componentes/dashboard/InofrmacionContactos";


const Dashboard = () => {
    return (
        <section>
            <User />
            <Estado />
            <Asesor />
            <div className="grid grid-cols-6 gap-3 mt-3" >
                <div className="col-span-4 border-none min-h-full">
                <InformacionContactos />
                    
                 
                </div>
                <div className="col-span-2">
                    <Estadísticas />
                    
                </div>
                <div className="col-span-4">
                    <GraficoHoras />
                </div>
                <div className="col-span-2">
                <AccionesRapidas /> 
                </div>


            </div>
        </section>
    );
}
export default Dashboard;