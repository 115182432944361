import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import FormularioCargar from "../componentes/cargarcontactos/FormularioCargar";

const CargarContactosPanel = () => {

    return (
        <section>
            <Breadcrumbs isDisabled className="mb-6">
                <BreadcrumbItem>Principal</BreadcrumbItem>
                <BreadcrumbItem active>Cargar Contactos del Panel</BreadcrumbItem>
            </Breadcrumbs>
            <h2 className="text-xl">
                Cargar Contactos
            </h2>
            <p className="text-gray-400 text-[13px]">Carga contactos desde el panel </p>
            <div className="py-3">
            </div>
            <FormularioCargar />
        </section>
    );
}
export default CargarContactosPanel;