import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import FormularioEnviar from "../componentes/enviarcontcatos/FormularioEnviar";

const DescargarContacto = () => {

    return (
        <section>
            <Breadcrumbs isDisabled className="mb-6">
                <BreadcrumbItem>Principal</BreadcrumbItem>
                <BreadcrumbItem active>Enviar Contactos</BreadcrumbItem>
            </Breadcrumbs>
            <h2 className="text-xl">
                Enviar Contactos
            </h2>
            <p className="text-gray-400 text-[13px]">Envia tus contactos a cualquiera de los paneles </p>
            <div className="py-3">
                <FormularioEnviar />
            </div>

        </section>
    );
}
export default DescargarContacto;